html {
  background-color: #FFFFFF;
  box-sizing: border-box;
}

body, h2, h3 {
  font-family: 'Roboto', sans-serif;
}

*,
*:before,
*:after {
  box-sizing: inherit;
  padding: 0;
  margin: 0;
}
:root {
  --text-primary-color: #2F2E2E;
  --heаding-primary-color: #2F2E2E;
  --bg-primary-color: #E3F3F9;
  --link-primary-color: #2679DB;
  --button-primary-color: #3CB09C;
  --paragrapgh-color: #F7F7F7;
  --li-color: #384575;
  --li-circle-color: #3CB09C;
  --bg-box: #F7F7F7;
}

body {
  font-size: 17px;
  font-weight: 300;
  line-height: 30px;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  scroll-behavior: smooth;
  margin: 0px;
}

h1 {
  font-family: PT Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 55px;
  line-height: 70px;
  text-transform: uppercase;
  color: var(--heаding-primary-color);
  padding: 25px 0;
}

h2 {
  font-style: normal;
  font-weight: bold;
  font-size: 33px;
  line-height: 42px;
  text-align: center;
  text-transform: uppercase;
  color: var(--heаding-primary-color);
  padding: 25px 0;
}
h3{
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 30px;
  text-transform: uppercase;
  padding: 25px 0;
}
p{
  font-size: 17px;
  line-height: 30px;
  color: var(--text-primary-color);
  padding: 20px 0;
}
p+p{
  padding-top: 0px;
}

a {
  text-decoration: none;
  color: var(--link-primary-color);
}
ul{
  list-style: none;
}



@media only screen and (max-width: 867px) {
  h1{
      font-size: 32px;
      line-height: 38px;
      text-align: center;
  }
  h2{
      font-size: 25px; 
      line-height: 28px;
      text-align: center;
  }
  h3{
      font-size: 22px; 
      line-height: 25px;
      text-align: center;
  }
  p, body{
      font-size: 15px;
      text-align: center;
  }
}
/* General */


/* Banner */
.banner{
  background: #FFFFFF;
  box-shadow: 0px 4px 105px rgba(34, 48, 74, 0.2);
  border-radius: 0px 0px 10px 10px;
  padding: 10px 20px 10px 10px;
  display: grid;
  gap: 30px;
  align-items: center; 
  grid-template-columns: 0.8fr 2fr 0.7fr;
}
.banner .logo-box{
  background: #EAF2F8;
  border-radius: 10px;
}
.banner .logo-box a{
  background: url(../image/domainer_logo.svg);
  background-repeat: no-repeat;
  background-position: center center;
  color: transparent;
  display: block;
  font-size: 0;
  padding: 20px 30px;
  height: 138px;
}
.banner .title{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 30px;
  margin-bottom: 15px;
  color: #090909;
}
.banner .info{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #7D7F82;
  position: relative;
  margin: 0 0 0 15px;
  padding: 0;
  background-color: transparent;
}
.banner .info:before{
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 6px;
  height: 100%;
  margin-left: -15px;
  border-radius: 10px;
  background: #F27558;
}
.banner .domaner-btn{
  background: #F27558;
  transition: all 0.5s ease-in-out;
  border-radius: 10px;
  padding: 23px 5px;
  display: block;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;
  text-align: center;
  color: #000000;
}
.banner .domaner-btn:hover {
  background-color: rgb(249, 173, 141);
}
@media only screen and (max-width: 1090px) {
  .banner .title{
      font-size: 22px;
      line-height: 50px;
      margin-bottom: 10px;
  }
  .banner .info{
      font-size: 18px;
  }
  .banner .domaner-btn{
      font-size: 18px;
  }
  .banner .domaner-btn {
      font-size: 18px;
      line-height: 22px;
      display: table;
      margin: 0 auto;
      padding: 23px 30px;
  }
}
@media only screen and (max-width: 940px) {
  .banner{
      grid-template-columns: 1fr;
      padding: 10px;
      margin: 0 20px;
  }
  .banner .logo-box a{
      background: url(../image/domainer_logo_mob.svg);
      background-repeat: no-repeat;
      background-position: center center;
      height: 66px;
  }
  .banner .title{
      line-height: 24px;
      text-align: center;
  }
  .banner .info{
      font-size: 16px;
      line-height: 20px;
      text-align: center;
  }
  .banner .info:before{
      content: none;
  }
}
@media only screen and (max-width: 440px) {
  .banner .domaner-btn {
      display: block;
      padding: 23px 0px;
  }
}

.container.bannerimg::after {
  display: none
}

.container {
  margin-left: auto;
  margin-right: auto;
  max-width: 1149px;
}
@media only screen and (max-width: 1025px) {
  .container {
      padding: 0 20px;
  }
  .block {
      padding: 25px 0!important;
  }
}
.grid {
  display: grid;
  grid-gap: 20px;
  gap: 20px;
}
.col2-1{
  grid-template-columns: 2fr 1fr;
}
.col1-3{
  grid-template-columns: 2fr 4fr;
}
.col3-1{
  grid-template-columns: 4fr 2fr;
}
.text-white{
  color: white;
}
.text-center{
  text-align: center;
}
.bg-grey{
  background-color: var(--bg-box);
}
.block{
  padding: 50px 0;
}
.slide-down {
  height: 0;
  overflow: hidden;
  transition: height 0.2s linear;
}
.m-slide-down-measure p {
  padding-top: 20px;
  margin: 0 !important;
}
.m-visible {
  display: none;
}
.m-hidden {
  display: initial;
}
@media only screen and (max-width: 867px){
  .mobile-detector {
      display: none;
  }

  .m-hidden {
      display: none;
  }

  .m-visible {
      display: initial;
  }

  .m-slide-down {
      -moz-transition: height .5s;
      -ms-transition: height .5s;
      -o-transition: height .5s;
      -webkit-transition: height .5s;
      transition: height .5s;
      height: 0;
      overflow: hidden;
  }
  .col2-1, .col1-3, .col3-1{
      grid-template-columns: 1fr;
  }
}

/* Header */

.scroll-to-link > *{
  pointer-events: none;
}
.site-header {
  width: 100%;
  background:  rgba(64, 204, 72, 0.77);
  padding: 0 0 30px;
  position: relative;
}

.site-header .site-logo {
  position: relative;
  padding: 20px 0;
}

.site-header .site-logo .main-logo, .footer .main-logo{
  display: block;
  font-size: 0;
  height: 41px;
  max-width: 100%
}
.site-header .site-logo .main-logo{
  background: url(../image/logo.svg) no-repeat 0 0/contain;
}
.site-header .intro-entry{
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 50px 0;
  gap: 20px;
}

.site-header .title{
  z-index: 1;
  position: relative;
}
.site-header .container{
  position: relative;
}
.site-header .container::after{
  content: '';
  background-image: url("../image/hero-image.webp");
  height: 100%;
    width: 50%;
    right: 0;
    bottom: 0;
    top: 0;
    margin-top: auto;
    margin-bottom: auto;
    position: absolute;
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 1;
}
.site-header::before {
  content: "";
  position: absolute;
  width: 60%;
  height: 85%;
  border-radius: 50%;
  right: 0;
  top: 0;
  margin-top: auto; 
  margin-bottom: auto; 
  opacity: 0.6;
  background:  #00E8DA;
  filter: blur(200px);
}
@media (min-width: 1710px) {
  .site-header .intro-entry{
      padding: 80px 0;
  }
}
@media (min-width: 868px) and (max-width:1025px) {
  .site-header::after{
      height: 40%;
  }
}
@media only screen and (max-width: 867px) {
  .site-header{
      padding: 0;
  }
  .site-header .container{
      padding: 0;
  }
  .site-header .intro-entry{
      grid-template-columns: 1fr;
      padding: 0px 20px 40px 20px;
  }
  .main-image{
      background-image: url(../image/hero-image.webp);
      background-size: contain;
      background-position: center;
      width: 100%;
      height: 150px;
      background-repeat: no-repeat;
      grid-row: 1;
  }
  .site-header:before{
      height: 50%;
      opacity: .6;
      width: 100%;
  }
  .site-header .container::after{
      content: none;
  }
  .site-header .intro-entry p{
      width: 100%;
  }
  .site-header .site-logo{
      padding-left: 20px;
      padding-right: 20px;
  }

}

/* Read more */

.m-slide-down {
  height: 0;
  overflow: hidden;
  -moz-transition: height .5s;
  -ms-transition: height .5s;
  -o-transition: height .5s;
  -webkit-transition: height .5s;
  transition: height .5s;
}
.m-hidden, .mobile-detector {
  display: none;
}
.m-visible {
  display: initial;
}
.m-hidden {
  display: initial;
}
.m-slide-down-measure p {
  margin: 0!important;
  padding-top: 20px;
}
.m-readmore-btn{
  background: transparent;
  border: none;
  text-transform: uppercase;
  font-size: 0;
  font-weight: 500;
  color: white;
  width: 100%;
  margin: 15px auto 50px;
}

.m-readmore-btn::before {
  cursor: pointer;
  display: inline-block;
  width: 25px;
  height: 25px;
  vertical-align: top;
  margin-left: 5px;
  color: white;
  font-size: 24px!important;
}

.m-readmore-btn.read-more-2::before {
  content: "\002B";
}
.m-readmore-btn.collapse-2::before{
  content: "\2212";
}
.m-readmore-btn.read-more-2:hover::before, .m-readmore-btn.collapse-2:hover::before{
  color: var(--button-primary-color)
}
.m-readmore-btn * {
  pointer-events: none;
}
.m-visible {
  display: initial;
}
.m-hidden, .mobile-detector {
  display: none;
}

/* Navigation */

.btn-group{
  box-shadow: 0px 6px 17px rgba(0, 0, 0, 0.14);
  border-radius: 22px;
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 2fr 3fr 3fr 2fr 1fr;
  margin: 0 auto;
  position: relative;
  z-index: 2;
  top: 80px;
}
.btn {
  display: inline-block;
  font-weight: 600;
  font-size: 20px;
  line-height: 35px;
  cursor: pointer;
  color: #FFFFFF;
  background-color: #379e8c;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: none;
  padding: 23px 0px;
  border-radius: 22px;
  transition: background-color .15s ease-in-out;
  width: 100%;
}
.btn:hover {
  color: #fff;
  background-color: #1C7B6B;
}
.btn-group li:not(:last-child):not(.dropdown-toggle) .btn{
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 1px solid #3CB09C;
}
.btn-group li:not(:first-child) .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group .btn+.btn{
  margin-left: -5px;
}
.btn-group>.btn:first-child {
  margin-left: 0;
}
.button {
  -webkit-backface-visibility: hidden;
  background-color: #3CB09C;
  background-image: radial-gradient(circle, #66cebc 0%, #3CB09C 100%);
  border-radius: 22px;
  font-size: 25px;
  line-height: 35px;
  display: inline-block;
  font-style: normal;
  font-weight: 700;
  padding: 20px 60px;
  white-space: nowrap;
  z-index: 1;
  color: #fff;
  position: relative;
  text-decoration: none;
}
.button:after {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 22px;
  background-image: radial-gradient(circle, #9bd49e 0%, #77D47C 100%);
  transition: opacity 0.5s ease-out;
  z-index: 2;
  opacity: 0;
}

.button span {
  position: relative;
  z-index: 3;
}
  @media (min-width: 1300px){
      a.button {
          min-width: 170px;
      }
  }
  @media only screen and (max-width: 1067px){
      a.button {
          box-shadow: none;
          display: block;
          min-width: auto;
          padding: 20px;
          text-align: center;
      }
      .site-header .site-nav{
          z-index: auto;
      }
      .nav-visible .site-logo{
          padding-top: 10px;
      }
      .site-nav .icon{
          border-top: 2px solid white;
          height: 25px;
          width: 30px;
          box-sizing: border-box;
          position: absolute;
          z-index: 30;
          right: 20px;
          top: 25px;
          cursor: pointer;
          -webkit-transition: all 0.3s ease-in;
          -moz-transition: all 0.3s ease-in;
          -khtml-transition: all 0.3s ease-in;
          -o-transition: all 0.3s ease-in;
          transition: all 0.3s ease-in;
      }
      .site-nav .icon:before {
          content: "";
          display: block;
          position: absolute;
          height: 2px;
          width: 30px;
          right: 0;
          background: white;
          top: 10px;
          -webkit-transition: all 0.3s ease-in;
          -moz-transition: all 0.3s ease-in;
          -khtml-transition: all 0.3s ease-in;
          -o-transition: all 0.3s ease-in;
          transition: all 0.3s ease-in;
      }
      .site-nav .icon:after {
          content: "";
          display: block;
          position: absolute;
          height: 2px;
          width: 30px;
          right: 0;
          background: white;
          bottom: 0;
          -webkit-transition: all 0.3s ease-in;
          -moz-transition: all 0.3s ease-in;
          -khtml-transition: all 0.3s ease-in;
          -o-transition: all 0.3s ease-in;
          transition: all 0.3s ease-in;
      }
      .nav-visible .site-nav .icon{
          border: transparent;
      }
      .nav-visible .site-nav .icon:before {
          -webkit-transform: rotate(45deg);
          -moz-transform: rotate(45deg);
          -khtml-transform: rotate(45deg);
          -o-transform: rotate(45deg);
          transform: rotate(45deg);
          width: 33px;
          right: -2px;
      }
      .nav-visible .site-nav .icon:after {
          -webkit-transform: rotate(135deg);
          -moz-transform: rotate(135deg);
          -khtml-transform: rotate(135deg);
          -o-transform: rotate(135deg);
          transform: rotate(135deg);
          width: 33px;
          right: -2px;
          top: 10px;
      }
      .nav-visible .site-nav{
          position: fixed;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          bottom: 0;
          overflow-x: hidden;
          overflow-y: auto;
          text-align: left;
          z-index: 5;
          background: rgb(44,77,161);
          background: linear-gradient(90deg,  rgba(64, 204, 72, 1) 0%, #00E8DA 100%);
      }
      .site-header.nav-visible .container{
          display: flow-root;
      }
      .site-nav>ul{
          opacity: 0;
          visibility: hidden;
          display: none;
      }
      .nav-visible .site-nav>ul {
          display: block;
          justify-content: center;
          align-items: center;
          opacity: 1;
          visibility: visible;
          position: fixed;
          width: 100%;
          height: 70%;
          top: 70px;
          left: 0;
          bottom: 0;
          overflow-x: hidden;
          overflow-y: auto;
          text-align: center;
          padding: 15px;
      }
      .nav-visible .site-nav>ul>li:hover{
          background-color: #ffffff40;
          border-radius: 22px;
      }
      
      .nav-visible .site-nav>ul>li a{
          font-weight: 600;
          font-size: 20px;
          line-height: 25px;
          text-align: center;
          color: #F3F3F3;
          white-space: break-spaces;
      }

      .nav-visible  .btn-group{
          box-shadow: none;
      }
      .nav-visible .site-nav>ul>li a.btn ,  .nav-visible .btn-group li:not(:last-child):not(.dropdown-toggle) .btn, .nav-visible .btn-group li:not(:first-child) .btn{
          border: none;
          background-color: transparent;
      }
}
.what-is{
  position: relative;
  z-index: 1;
  margin-top: 50px;
}
#what-is.block{
  padding-bottom: 0;
}
@media only screen and (max-width: 1067px){
  .what-is{
      margin-top: 0px;
  }
}
/* Types */

.types{
  position: relative;
  top: 50px;
  margin-bottom: 50px;
  margin-top: 20px;
}

.type{
  border-radius: 22px;
  padding: 0px 40px 20px 40px;
  margin-bottom: 30px;
}
.type:first-of-type{
  margin-top: 20px;
}
.type .bg-icon{
  position: relative;
}
.type .bg-icon{
  background-position: top center;
  background-repeat: no-repeat;
}

.type.bg-light .bg-icon{
  background-image: url("../image/lotto.webp");
}
.type.bg-dark .bg-icon{
  background-image: url("../image/competitions.webp");
}
.type.bg-dark-two .bg-icon{
  background-image: url("../image/charity.webp");
}

.type.bg-light#artists .bg-icon{
  background-image: url("../image/artists.webp");
}
.type.bg-dark#charity-competition .bg-icon{
  background-image: url("../image/charity-competitions.webp");
}
.bg-light{
  background: #4FD956;
}
.bg-dark{
  background: #4ACD90;
}
.type.bg-dark-two{
  background: #12A17E;
  box-shadow: 0px 4px 19px rgba(0, 0, 0, 0.15);
}

.type h3{
  padding-top: 40px;
}
.type a{
  color: white;
  text-decoration: underline;
  font-weight: 800;
}


@media only screen and (max-width: 867px) {
  .type{
      padding: 0 20px;
  }
  .type.bg-light .bg-icon, .type.bg-dark .bg-icon, .type.bg-dark-two .bg-icon, .type.bg-dark-three .bg-icon{
      display: block;
      height: 200px;
      background-size: contain;
  }
  .type.bg-dark .bg-icon, .type.bg-dark-three .bg-icon{
      grid-row: 1
  }
  .type h3{
      padding-top: 0px;
  }
}
/* F&Q */
.faq-wrapper {
  padding: 60px 0 80px;
}
.faq a{
  font-weight: 700;
}
.faq-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 20px 30px;
}
.faq-title h3{
  font-size: 20px;
  line-height: 23px;
  color: #454545;
  text-transform: none;
}
.faq.expanded .faq-title{
  padding-bottom: 20px;
  border-bottom: 1px solid var(--bg-box);
}

.faq-btn {
  position: relative;
  float: right;
  height: 20px;
  width: 30px;
}
.faq-btn::after{
  content: "";
  position: absolute;
  right: 0;
  left: 0;
  margin: 0 auto;
  width: 10px;
  height: 10px;
  border-top: 2px solid grey;
  border-left: 2px solid grey;
  transform: rotate(225deg);
  -ms-transform:rotate(225deg);
  -webkit-transform:rotate(225deg);
  -webkit-transition: -webkit-transform .5s ease-in-out;
  -ms-transition: -ms-transform .5s ease-in-out;
  transition: transform .5s ease-in-out;  
}
.faq.expanded .faq-btn::after {
  transform: rotate(45deg);
  -ms-transform:rotate(45deg);
  -webkit-transform:rotate(45deg);
}

.faq .faq-btn:hover {
  cursor: pointer;
}

.faq {
  transition: all 0.1s ease-in;
  background-color: white;
  border-radius: 22px;

}
.faq+.faq{
  margin-top: 30px;
}
.faq.collapsed .faq-body {
  display: none;
}
.faq-body{
  padding: 0 30px;
}
.faq.expanded {
  background: white;
  border-radius: 22px;
  border: none;
}

.faq.faq.expanded+.faq {
  border-top: 0px;
}

.faq.expanded .faq-title h3 {
  margin-bottom: 0px;
}

.faq .faq-title h3 {
  pointer-events: none;
  padding: 0;
}

.faq-listing {
  margin-top: 30px;
}

.read-more-faq {
  width: 100%;
  border: 1px solid var(--link-primary-color);
  border-radius: 69px;
  height: 59px;
  background: transparent;
  font-weight: bold;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  line-height: 24px;
  color: var(--link-primary-color);
}

.read-more-faq:active {
  background-color: #E2FCFC;
}

@media only screen and (max-width: 867px) {
  .faq-wrapper {
      padding: 30px 0;
  }
  .faq, .faq-title{
      padding: 10px;
  }
  .faq.expanded {
      border-radius: 15px;
  }
}
.conclution-box {
  background: #2B2E33;
  padding: 30px 130px 50px;
}
.conclution-box .button:hover{
  color: white;
  background-image: radial-gradient(circle, #9bd49e 0%, #77D47C 100%);
}
.contact-btn-box{    
  text-align: center;
  margin: 20px 0;
}
.contact-btn-box .button{
  cursor: pointer
}
@media only screen and (max-width: 867px) {
  .conclution-box{
      padding: 15px;
      top: 50px;
  }
  .contact-btn-box .button{
    padding: 15px 30px;
    font-size: 18px;
  }
}
.footer{
  background: #202022;
}
.footer .footer-inner{
  text-align: center;
  color: #707278;
  padding: 20px 0;
  font-size: 15px;
}

@mixin transition($params) {
  -webkit-transition: $params;
     -moz-transition: $params;
   -khtml-transition: $params;
       -o-transition: $params;
          transition: $params;
}

@mixin rotate($params) {
  -webkit-transform: rotate($params);
     -moz-transform: rotate($params);
   -khtml-transform: rotate($params);
       -o-transform: rotate($params);
          transform: rotate($params);
}